import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'

export default class Header extends React.Component {

  constructor(props) {
    super(props)
    this.state = { action: '/' }
  }

  linkc = () => {
    if (Array.from(document.querySelector(".navbar-collapse").classList).filter(item => item === 'show').length > 0) {
      document.querySelector(".navbar-toggler").click()
    }
  }

  render() {
    let links = [
      { href: '#/', text: '首页' },
      { href: '#introduce', text: '公司介绍' },
      { href: '#scheme', text: '解决方案' },
      { href: '#center', text: '产品中心' },
      { href: '#news', text: '新闻中心' },
      { href: '#case', text: '客户案例' },
      { href: '#contact', text: '联系我们' }
    ].map(item => (
      <Nav.Link key={item.href} href={item.href} className={this.state.action === item.text ? 'action' : ''}>{item.text}</Nav.Link>
    ))
    return (
      <div className="header">
        <Navbar expand="lg" variant="light">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" onClick={this.linkc}>
              {links}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}
