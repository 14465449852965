import React from 'react'
import Product from '../components/product'
import '../style/home.scss'

let data = [
  {
    title: '大数据AI人工智能学习',
    context: '利用大数据AI人工智能技术，结合教育场景及特点通过深度学习、机器学习等技术，创建多个场景数据模型'
  },
  {
    title: '云计算',
    context: '在教育行业实践边缘计算技术，结合云服务、云计算和教育本地应用提供便捷服务'
  },
  {
    title: '业务中台技术',
    context: '支持业务能力复用、加速业务创新和个性化场景应用、统一能力服务规范、共享数据价值'
  },
  {
    title: '物联网应用',
    context: '集成教育视频盒子、教育一体机、互动课堂、电子白板、手机定位、VR教学设备、学生穿戴设备等'
  },
  {
    title: 'VR虚拟技术',
    context: 'VR实时直播、沉浸式课程体验、课外活动拓展体验交互式教与学体验等'
  },
  {
    title: '5G视频应用',
    context: '精品课程直播、教育培训、教务工作会议、大型教学活动现场直播、线上互动等'
  }
]

const list = data.map((item, index) => (
  <div key={index}>
    <div className="title"><img src={`./assets/app${index + 1}2x.png`} alt="" />{item.title}</div>
    <div className="context">{item.context}</div>
  </div>
))

function NewApp() {
  return (
    <div className="newapp">
      <div className="title">创新应用</div>
      <div className="list">{list}</div>
    </div>
  )
}

export default class Home extends React.Component {
  render() {
    return (
      <div className="home">
        <div className="one">
          <div className="context">
            <div className="big">教育智能生态大数据</div>
            <div className="sm">利用大数据、云计算、AI一体化服务教育，建立具有 “服务生态”、“数据生态”、“用户生态” 的大数据综合应用云平台，为教育助力</div>
          </div>
        </div>
        <div className="two"><Product /></div>
        <div className="three"><NewApp /></div>
      </div>
    )
  }
}