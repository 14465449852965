import React from 'react'
import './App.scss'
import './App.css'
import Router from './Router'
import Header from './components/header'
import Footer from './components/footer'

function App() {
  return (
    <div className="App">
      <Header />
      <Router />
      <Footer />
    </div>
  )
}

export default App
