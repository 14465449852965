import React from 'react'
import Product from '../components/product'
import '../style/center.scss'
import yjwz from '../assets/yjwz2x.png'

const middata = [
  {
    title: '省市区教委辖区管理场景',
    context: (<p>统一服务平台， 实现一站式教育智汇服务，建设教育大数据中心，有效整合教育资源，实现分层数据化管理；同时通过教研合作，校际、校内教师帮扶，提升整体教学水平，促进教育均衡。</p>)
  },
  {
    title: '学校教学管理场景',
    context: (<p>以平台为支撑，通过微服务网关实现了对原有或新引入子应用系统链接，实现数据互联互通，打通数据孤岛，利用云计算、AI智能计算，为学校管理提供科学决策的依据，有效节约教育信息化硬件、软件资金与人力投入，满足教育服务应用软件的不断扩充，实现教育信息化科学、协调、可持续发展。</p>)
  },
  {
    title: '教师、学生的教与学场景',
    context: (<p>教师：教学研究，成绩分析，学情分析等借助大数据分析技术，有效的双向互动，进行实时反馈，构建高效的互动教学，全面评估教学动态，为教师教学减负，将有效教学、高效教学常态化，提高教师教改质量。<br></br><br></br>
      学生：增加学习时间和空间的延伸性，使学生有更好的学习指引，学习互助，从而使学习更加灵活、积极和有效，学生的兴趣、特长和潜能得以充分发挥。</p>)
  },
  {
    title: '合作运营服务场景',
    context: (<p>运营模式：采用购买服务，服务运营。</p>)
  }
]

export default class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = { midobj: middata[0], index: 0 }
  }

  updateMid = (index, e) => {
    if (index === -1) {
      index = 3
    }
    if (index === 4) {
      index = 0
    }
    const tabs = document.querySelectorAll('.tabs>div')
    tabs.forEach((tab, i) => {
      tab.classList.remove('action')
      if (index === i) {
        tab.classList.add('action')
      }
    })
    this.setState({
      midobj: middata[index],
      index: index
    })
  }

  render() {
    return (
      <div className="center">
        <div className="one">
          <div className="context">
            <div className="big">数据生态，服务生态，用户生态</div>
            <div className="sm">深化信息技术与教育教学、教育管理的融合，强化教育信息化对教学改革</div>
          </div>
        </div>
        <div className="two"><Product /></div>
        <div className="three">
          <div className="title">教育智能大数据云平台理念</div>
          <div className="context">中腾数字立足于未来教育、互联网5G+教育的生态赋能者角色，建设中腾数字智能大数据云平台，成为教育生态枢纽的实际载体，提供“1+n+N”的应用技术集成及数据价值共享服务。</div>
          <img src={yjwz} alt="" />
        </div>
        <div className="four">
          <div className="title">大数据云平台服务场景</div>
          <div className="tabs">
            <div onClick={(e) => this.updateMid(0, e)} className="action">{middata[0].title}</div>
            <div onClick={(e) => this.updateMid(1, e)}>{middata[1].title}</div>
            <div onClick={(e) => this.updateMid(2, e)}>{middata[2].title}</div>
            <div onClick={(e) => this.updateMid(3, e)}>{middata[3].title}</div>
          </div>
          <div className="change">
            <div className="left" onClick={(e) => this.updateMid(this.state.index - 1, e)}></div>
            <div className="mid">
              <img src={`./assets/cj${this.state.index + 1}2x.png`} alt="" />
              <div>
                <span>{this.state.midobj.title}</span>
                {this.state.midobj.context}
              </div>
            </div>
            <div className="right" onClick={(e) => this.updateMid(this.state.index + 1, e)}></div>
          </div>
        </div>
      </div>
    )
  }
}