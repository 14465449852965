import React from 'react'
import '../style/case.scss'
import gdmap from '../assets/xgdmap.png'

let data = ['广州市荔湾区真光中学', '广州市西关培正小学', '广州市第四中学', '广州市五眼桥小学', '广州市第二十三中学', '中国联通']

const list = data.map((item, index) => (
  <div key={index}>
    <img src={`./assets/company${index + 1}2x.png`} alt="" />
    <span>{item}</span>
  </div>
))

export default class Case extends React.Component {
  render() {
    return (
      <div className="case">
        <div className="one">
          <div className="context">
            {/* <div className="big">客户案例</div>
            <div className="sm"></div> */}
            <div className="gdmap"><img src={gdmap} alt="" /></div>
          </div>
        </div>
        <div className="two" style={{display: 'none'}}>{list}</div>
        <div className="three" style={{display: 'none'}}><img src={gdmap} alt="" /></div>
      </div>
    )
  }
}