import React from 'react'
import '../style/introduce.scss'
import left from '../assets/iLeft2x.png'
import right from '../assets/iRight2x.png'

export default class Introduce extends React.Component {
  render() {
    return (
      <div className="introduce">
        <div className="one">
          <div className="context">
            <div className="big"></div>
            <div className="sm"></div>
          </div>
        </div>
        <div className="two">
          <div className="context">
            <div className="title">公司简介</div>
            <p>
              &emsp;&emsp;广东省中腾数字科技有限公司专注研发教育智能生态大数据云平台，按照于国家教育部技术标准，为客户提供“平台开发+运营服务+内容服务”一体化的教育教学信息化整体解决方案。<br></br><br></br>
              &emsp;&emsp;公司的“1+n+N”业务运营服务模式，不但为教育用户服务，还为运营商、教育内容提供商和项目服务提供商提供教育行业增值服务应用，提供整套技术和运营解决方案，建立教育增值服务稳定发展的生态合作链。公司虔诚的服务态度，积极的行动能力，卓越的技术保障机制和对教育行业的深刻理解，以技术研究为基石，充分利用云计算、大数据、物联网、人工智能等先进现代技术，围绕客户多变的需求与反馈，不断升级与优化系统，为教育部门和学校提供高效工作的支撑技术手段，为教师和家长提供丰富的教学资源和家校沟通工具，为学生创造快乐成长的源泉。
            </p>
          </div>
          <div className="intr">
            <div className="title">公司愿景</div>
            <div className="sub">
              <img src={left} alt="" />
              创造新型的教育智能生态大数据云平台，打造教育智能服务运营生态圈
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}