import React from 'react'

let data = [
  {
    name: '教育智能生态大数据云平台',
    imgUrl: ''
  },
  {
    name: '教与学精准诊断系统',
    imgUrl: ''
  },
  {
    name: '基础数据中心',
    imgUrl: ''
  },
  {
    name: '教学平台',
    imgUrl: ''
  },
  {
    name: '教务管理系统',
    imgUrl: ''
  },
  {
    name: '资源平台',
    imgUrl: ''
  },
  {
    name: '巡查系统',
    imgUrl: ''
  },
  {
    name: '网盘',
    imgUrl: ''
  }
]

const list = data.map((item, index) => (
  <div key={index}>
    <img src={`./assets/prod${index + 1}2x.png`} alt="" />
    <span>{item.name}</span>
  </div>
))

function product() {
  return (
    <div className="product">
      <div className="title">产品体系</div>
      <div className="list">{list}</div>
    </div>
  )
}

export default product
