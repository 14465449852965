import React from 'react'
import '../style/news.scss'

const data = [
  {
    url: 'https://www.jiemodui.com/N/116537.html',
    title: '广东中小学各年级暑假开始时间不晚于8月1日',
    source: (<span>2020-05-07&emsp;&emsp;来源：芥末堆&emsp;&emsp;作者：刘小黑</span>),
    context: '今日，广东省教育厅防控新型冠状病毒肺炎疫情工作领导小组印发了《关于做好2020年春季学期中小学教育教学工作的通知》（简称《通知》）。《通知》称，中小学各年级法定节假日不调课，因延迟开学耽误的教学时间，可采取适当延后暑期放假时间的方式补偿，原则上中小学各年级暑假开始时间不得晚于8月1日。'
  },
  {
    url: 'http://www.moe.gov.cn/srcsite/A06/s3321/202003/t20200323_433672.html',
    title: '教育部办公厅关于做好2020年春季学期中小学教育教学工作的通知',
    source: (<span>2020-03-19&emsp;&emsp;来源：教育部办公厅&emsp;&emsp;作者：教育部办公厅</span>),
    context: '全面贯彻落实党中央、国务院关于坚决打赢新冠肺炎疫情防控阻击战的决策部署，坚持把疫情防控放在首位，认真制定教育教学计划，统筹处理好疫情防控与教育教学的关系、战“疫”精神教育与学科教学的关系、开学前居家学习与开学后恢复正常教学的关系...'
  },
  {
    url: 'https://baijiahao.baidu.com/s?id=1667277630155809354&wfr=spider&for=pc',
    title: '朱永新：后疫情时代，教育需要“新基建”',
    source: (<span>2020-05-21&emsp;&emsp;来源：新京报&emsp;&emsp;作者：新京报社官方帐号</span>),
    context: '作为民进界别的召集人，5月20日下午4点半在全国政协常委会议厅参加小组召集人会议。张庆黎副主席就如何聚精会神开好会议提出了要求，他要求要做到大会会期缩短、协商议政活动不缩水，把这次“精简版”大会开成“精华版”大会，做到确保无疫情、无感染...'
  },
  {
    url: 'https://www.jiemodui.com/N/112820.html',
    title: '经济体量全球第一，美国也没做好基础教育 | 看世界',
    source: (<span>2020-05-21&emsp;&emsp;来源：芥末堆&emsp;&emsp;作者：阿宅</span>),
    context: '去年12月，经济合作与发展组织（OECD）发布了2018年PISA最新成绩。在79个国家和地区的约60万学生中，中国学生在阅读、数学和科学三项测试中成绩均位列第一。'
  },
  {
    url: 'https://www.jiemodui.com/N/116601',
    title: '北京：2020年将建设教育大数据平台，保障线上教学',
    source: (<span>2020-05-09&emsp;&emsp;来源：芥末堆&emsp;&emsp;作者：南墙</span>),
    context: '近期，北京市教委印发了《2020年北京市教育信息化和网络安全工作要点》（以下简称《要点》），刚好为此提供了一系列解决方案。与往年不同的是，相比较以学校为单位的教育信息化建设，今年北京市的《要点》着眼于全市，更强调“一体化”这个概念。'
  },
  {
    url: 'http://jyj.gz.gov.cn/yw/jyyw/content/post_5832421.html',
    title: '广州市教育局致全市中小学生家长的一封信',
    source: (<span>2020-05-17&emsp;&emsp;来源：广州市教育局&emsp;&emsp;作者：广州市教育局</span>),
    context: '从4月27日起，全市中小学校分批错峰返校。经过这个“超长假期”，孩子们返校后难免会出现不适应感，比如生物钟的不适应、学习状态的不适应和人际关系的不适应等等。学校将科学、有序地做好学生的情绪调节和心理调适工作，尽量把疫情对他们学习、生活和情绪带...'
  }
]

const list = data.map((item, index) => (
  <div key={index}>
    <img src={`./assets/news${index + 1}.png`} alt="" />
    <div className="right">
      <div className="title">{item.title}</div>
      <div className="source">{item.source}</div>
      <div className="context">{item.context}</div>
      <a href={item.url} target="_blank" rel="noopener noreferrer">查看详情</a>
    </div>
  </div>
))

export default class News extends React.Component {

  render() {
    return (
      <div className="news">
        <div className="one">
          <div className="context">
            <div className="big">新闻中心</div>
            <div className="sm"></div>
          </div>
        </div>
        <div className="two">{list}</div>
        <div className="pages">
          <span>共1页&emsp;共6条信息</span>
          <button>1</button>
        </div>
      </div>
    )
  }
}