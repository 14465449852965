import React from 'react'
import '../style/scheme.scss'
import nadd from '../assets/nadd2x.png'
import girdmid from '../assets/girdmid2x.png'

let girdData = [
  {
    title: '校园信息管理化',
    context: '数字化校园管理和教学方式。'
  },
  {
    title: '教与学融合',
    context: '实现校园资源共享增强课堂教学过程的交互性。'
  },
  {
    title: '校园信息安全',
    context: '建立安全管理机制，强身份认证、权限管理控制、数据加密、隐私保护。'
  },
  {
    title: '教与学诊断分析',
    context: '为教研部门提供决策手段。'
  }
]

const girdList = girdData.map((item, index) => (
  <div key={index}>
    <img src={`./assets/gird${index + 1}2x.png`} alt="" />
    <div>
      <span className="tt">{item.title}</span>
      <span>{item.context}</span>
    </div>
  </div>
))

let data = [
  {
    title: '数据优势',
    subTitle: '基础数据、应用数据、运营数据（行为数据、状态数据等）',
    context: '统一服务平台，单点登录，通过标准化接口，实现1+n+N多系统之间的数据交换，多格式的数据交换，为第三方服务商提供标准接口，实现一站式教育智汇服务，建设区域教育大数据中心。'
  },
  {
    title: '模型优势',
    subTitle: '用户画像模型、智能教学模型、成绩分析模型等',
    context: '通过建设教育大数据云平台，将业务系统应用中产生的数据，通过汇聚、互通、挖掘分析可以对业务进行诊断、预警、改进跟踪，提高教育决策水平。'
  },
  {
    title: '开放能力优势',
    subTitle: '对接、打通、融合能力、开放平台、开放生态',
    context: '以区域为主体建立基础数据中心和中台交换平台，通过数据同步或API接口，将各区域学校不同业务连接起来。子业务系统向大数据基础云平台提出数据请求或提供需求数据从而实现业务系统间数据融合。'
  },
  {
    title: '聚合能力优势',
    subTitle: '资料聚合、应用聚合、数据聚合、流程聚合、管理聚合',
    context: '实现系统横向协同、纵向连通，通过建立基础数据中心和交换平台实现应用系统纵向互联互通、横向业务协同作业。'
  }
]

const list = data.map((item, index) => (
  <div key={index}>
    <div className="title">
      <img src={`./assets/adv${index + 1}2x.png`} alt="" />
      <div>
        <span>{item.title}</span>
        {item.subTitle}
      </div>
    </div>
    <div className="context">{item.context}</div>
  </div>
))

export default class Scheme extends React.Component {
  render() {
    let girdStyle = 'gird'
    if (!!window.ActiveXObject || "ActiveXObject" in window) {
      girdStyle = 'gird ieGird'
    }

    return (
      <div className="scheme">
        <div className="one">
          <div className="context">
            <div className="big">智能，生态，服务于教育所需</div>
            <div className="sm"></div>
          </div>
        </div>
        <div className="two">
          <div>
            <span>“1+n+N”服务模式</span>
            的内涵是贯穿教育智能信息化各环节，为教育智能生态提供集成+应用服务的全方位支持。<br></br>
            服务包括：<span>数据管理服务、大数据分析服务、应用管理服务等。</span>
          </div>
          <img src={nadd} alt="" />
        </div>
        <div className="three">
          <div className="title">平台特色</div>
          <div className={girdStyle}>
            {girdList}
            <div className="mid">
              <img src={girdmid} alt="" />
              <div>
                <span className="tt">学生成长诊断与分析</span>
                <span>依托各层级教育机构、学校的考试及诊断分析建立学生成长档案，结合精细化、颗粒式教学知识点，实现个性化资源推送。</span>
              </div>
            </div>
          </div>
        </div>
        <div className="four">
          <div className="title">平台优势</div>
          <div className="list">{list}</div>
        </div>
      </div>
    )
  }
}