import React from 'react'
import '../style/contact.scss'
import { Map, Marker } from 'react-amap';

export default class contact extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      markerPosition: { longitude: 113.27391152816055, latitude: 23.096544998412625 },
      mapKey: '9d0b6223c55b796035946142ad05b47a',
      mapZoom: 18
    }
  }
  render() {
    return (
      <div className="contact">
        <div className="one"></div>
        <div className="two">
          <div className="context">
            <div className="title">联系我们</div>
            <p>
              联系电话：&emsp;<span className="number"><a href="tel:4008083189">020-80928993</a></span>&emsp;&emsp;<span className="time">(工作日:9:00-18:00)</span>&ensp;<br></br>
              公司地址：广州市海珠区江南大道中168号601室（仅限办公）
            </p>
            <div className="map" >
              <Map zoom={this.state.mapZoom} amapkey={this.state.mapKey} center={this.state.markerPosition}>
                <Marker position={this.state.markerPosition} />
              </Map>
            </div>
          </div>
        </div>
      </div>
    )
  }
}