import React from 'react'
import keep from '../assets/keep2x.png'

function footer() {
  return (
    <div className="footer">
      <div className="content">
        <div className="left">
          <div className="title">公司简介</div>
          <div className="context">
            &emsp;&emsp;广东省中腾数字科技有限公司专注研发教育智能生态大数据云平台，按照于国家教育部技术标准为客户提供“平台开发+运营服务+内容服务”一体化的教育教学信息化整体解决方案。<br></br>
            &emsp;&emsp;公司的“1+n+N”业务运营服务模式，不但为教育用户服务，还为运营商、教育内容提供商和项目服务提供商提供教育行业增值服务应用，提供整套技术和运营解决方案，建立教育增值服务稳定发展的生态合作链。公司虔诚的服务态度，积极的行动能力，卓越的技术保障机制和对教育行业的深刻理解，以技术研究为基石，充分利用云计算、大数据、物联网、人工智能等先进现代技术，围绕客户多变的需求与反馈，不断升级与优化系统，为教育部门和学校提供高效工作的支撑技术手段，为教师和家长提供丰富的教学资源和家校沟通工具，为学生创造快乐成长的源泉。
          </div>
        </div>
        <div className="right">
          <div className="top">
            <div className="me">
              <div className="title">联系我们</div>
              <div className="context">
                地址：广州市海珠区江南大道中168号601室（仅限办公）<br></br>
                {/* 邮箱：邮箱<br></br> */}
                咨询热线： <a href="tel:4008083189">020-80928993</a>
              </div>
            </div>
            {/* <div className="code">
              <p>关注中腾数字公众号</p>
            </div> */}
          </div>
          <div className="keep">
            <img src={keep} alt="" />&emsp;
            <a href="http://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">粤ICP备2021167232号</a>&emsp;
            <img src={keep} alt="" />&emsp;
            <a href="http://www.beian.gov.cn" target="_blank" rel="noopener noreferrer">粤公安网备案 https://www.beian.gov.cn/</a>&emsp;
          </div>
          <div className="keep">
            版权所有：广东省中腾数字科技有限公司
          </div>
        </div>
      </div>
    </div>
  )
}

export default footer
