import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Home from './pages/home'
import Introduce from './pages/introduce'
import Scheme from './pages/scheme'
import Center from './pages/center'
import News from './pages/news'
import Case from './pages/case'
import Contact from './pages/contact'

const BasicRoute = () => (
  <HashRouter>
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/introduce" component={Introduce} />
      <Route exact path="/scheme" component={Scheme} />
      <Route exact path="/center" component={Center} />
      <Route exact path="/news" component={News} />
      <Route exact path="/case" component={Case} />
      <Route exact path="/contact" component={Contact} />
    </Switch>
  </HashRouter>
)

export default BasicRoute
